import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../Utils/FormInputs/TextField";
import Label from "../Utils/FormInputs/Label";
import { ErrorMessage } from "../Utils/styledComponents";
import styled from "@emotion/styled/macro";
import * as mq from "../../styles/media-queries.js";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { dateConvert, isValidDate } from "../Utils/ConvertDate";
import { isNotPastDate } from "../Utils/ConvertDate";

const FormField = styled.div({
  [mq.large]: {
    width: "480px",
  },
});

const ReceiveStock = ({
  products,
  submitted,
  handleSubmit,
  invoice,
  isSearching,
  isSearchSuccess,
  isSearchError,
  search,
  handleSearchInvoice,
  handleChangeInvoice,
  handleChangeproduct,
  isPending,
  status,
  isPendingDraft,
  handleSaveDraft,
  openConfirm,
  handleOpenConfirm,
  handleCloseConfirm,
}) => {
  const isValid = status === 1;
  const isInvalid = status === 2;

  const today = dateConvert()

  const buttons = (
    <>
      <button
        type="button"
        id="continue-receive-items"
        onClick={handleSubmit}
        className="btn btn-sm sms-info-btn"
      >
        Continue
      </button>
      <button
        type="button"
        onClick={handleCloseConfirm}
        className="btn btn-sm sms-gray-btn"
      >
        No
      </button>
    </>
  );
  return (
    <div>
      <CustomDialog
        title="Confirmation"
        open={openConfirm}
        handleClose={handleCloseConfirm}
        buttons={buttons}
      >
        <p>This action is irreversible, Are you sure you want to continue?</p>
      </CustomDialog>
      <form autoComplete="off" onSubmit={handleOpenConfirm}>
        <FormField>
          <Form.Group as={Row}>
            <Label name="Invoice" sm="2" column type htmlFor="invoice-number" />
            <Col sm="7">
              <TextField
                value={invoice}
                onChange={handleChangeInvoice}
                id="invoice-number"
              />
              {search && !invoice ? (
                <ErrorMessage>This is a required field</ErrorMessage>
              ) : null}
            </Col>
            <Col sm="2">
              <button
                type="button"
                id="search-invoice"
                onClick={handleSearchInvoice}
                className="btn btn-sm sms-info-btn"
                disabled={isPending}
              >
                {isSearching ? "Searching..." : "Search"}
              </button>
            </Col>
          </Form.Group>
        </FormField>
        {isSearchSuccess && isValid && products.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered table-sm profile-table strike-table">
              <thead>
                <tr>
                  <td>Product</td>
                  <td>Quantity Purchased</td>
                  <td>Batch#</td>
                  <td>Expiry Date</td>
                  <td>Received Quantity</td>
                </tr>
              </thead>
              <tbody>
                {products.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <TextField
                          type="text"
                          submitted={submitted}
                          name="batch_id"
                          value={item.batch_id}
                          onChange={(e) => handleChangeproduct(e, index)}
                          data-testid={`batch_id-${index}`}
                        />
                        {submitted && !item.batch_id ? (
                          <ErrorMessage>This is a required field</ErrorMessage>
                        ) : null}
                      </td>
                      <td>
                        <TextField
                          type="date"
                          data-testid={`expiry_date-${index}`}
                          submitted={submitted}
                          name="expiry_date"
                          value={item.expiry_date}
                          onChange={(e) => handleChangeproduct(e, index)}
                          min={today}
                        />
                        {isNotPastDate(item.expiry_date) ? <ErrorMessage>Enter a future date</ErrorMessage>  :
                         item.expiry_date && !isValidDate(item.expiry_date) ? (
                          <ErrorMessage>Please enter a valid date</ErrorMessage>
                        ) : submitted && !item.expiry_date ? (
                          <ErrorMessage>This is a required field</ErrorMessage>
                        )  :  null}
                      </td>
                      <td>
                        <TextField
                          type="number"
                          data-testid={`received_quantity-${index}`}
                          submitted={submitted}
                          name="received_stock"
                          value={item.received_stock}
                          onChange={(e) => handleChangeproduct(e, index)}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {submitted && !item.received_stock ? (
                          <ErrorMessage>This is a required field</ErrorMessage>
                        ) : null}
                        {submitted &&
                        +item.quantity <= 0 &&
                        item.received_stock !== "" ? (
                          <ErrorMessage>
                            Enter the correct quantity
                          </ErrorMessage>
                        ) : null}
                        {submitted && +item.received_stock !== +item.quantity ? (
                          <ErrorMessage>
                            Quantity received should be equal to the quantity purchased
                          </ErrorMessage>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
        {isSearchError ? <p>Failed to load products</p> : null}
        {isInvalid ? (
          <p>
            Stock already received for this invoice, please input another
            invoice
          </p>
        ) : null}
        {products.length > 0 && isValid ? (
          <>
            <button
              type="button"
              onClick={handleSaveDraft}
              disabled={isPendingDraft}
              className="btn btn-sm sms-info-btn px-4 mr-4"
              id="submit_stock_draft"
            >
              {isPending ? "Saving..." : "Save Draft"}
            </button>
            <button
              type="submit"
              disabled={isPending}
              className="btn btn-sm sms-btn px-4"
              id="submit-received-items"
            >
              {isPending ? "Saving..." : "Save Final"}
            </button>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default ReceiveStock;
