import React, {useEffect, useState} from 'react';
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {dateStringConvert} from "../../../Utils/ConvertDate";
import {useReusablePrescription} from "./useReusablePrescription";
import {useSundries} from './useSundries';
import {v4 as uuidv4} from 'uuid';


export const useEditPrescription = (actions, match, _token) => {
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, request}=match
    const [presc_id, setPrescId] = useState("");
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const obj = {actions, visit_id,setIsBlocking}

    const [id, setId] = useState({prescription_id:'', date_created:''});
    const [removedInvoices, setRemovedInvoices] = useState([]);
    const [editedInvoices, setEditedInvoices] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const {drugProps} = useReusablePrescription(obj)
    const {submitted, prescription, others,  setPrescription, setOthers, setSubmitted,other,
        isSubmitted,  setIsSubmitted, setOther, initialOthers, initialPrescState, alert, setAlert} = drugProps
    const {prescription_id} = id
    const {sundryProps,setSundries} = useSundries(obj)
    const {sundries} = sundryProps
    const [isSundries, setIsSundries] = useState(false)
    const [stoppedPresc, setStoppedPresc] = useState([])

    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;
    const [isOutPatient, setIsOutPatient] = useState(false)

    const handleToggleSundries = (e) => {
        setIsSundries(e.target.checked)

    }

    const handleOpenEdit = (item) => {
        const {id, date} = item
        setId({date_created: date, prescription_id: id})
        setOpenEdit(true)
        // setIsSundries(true)

    };

    const handleCloseEdit = () => {
        setOpenEdit(false)
    };


    // useEffect(() => {
    //     if(!prescription_id){
    //         return
    //     }
    //
    //     const formData = new FormData();
    //     formData.append('visit_id', visit_id);
    //     const url = patient_type === '1' ? 'get_previous_prescription_by_visit_id' : 'get_previous_inprescription_by_visit_id'
    //     axios.post(`${config.smsUrl}/cdoctor/${url}`, formData).then(res => {
    //         const resp = res.data;
    //         const data = !resp ? {} : resp;
    //         setRequest(data);
    //     }).catch(error => {
    //         logoutErrorMessage(error, null, actions)
    //     });
    // }, [prescription_id, stopPresc])

    useEffect(() => {
        if(!prescription_id){
            return
        }

        const prescrip = request.prescription ? request.prescription : [];
        const other_meds = request.other_med ? request.other_med : [];
        const dt = prescrip.filter(item=> item.prescription_id === prescription_id &&
            (
                (
                    (item.patient_type === 1 ) && ( (item.pay_status === 3 && item.status === 1) || (item.pay_status === 0 && item.status === 0) )
                ) ||
                ( item.patient_type === 2 && (item.status === 3 || item.status === 0 ) )
            ))
            .filter(drug => drug.product_id).map(presc => ({
            ...presc,
            drug_name: presc.product_name, dosage: presc.dosage, status: presc.status,
            number_of_days: presc.number_of_days, key_information: presc.key_information, rate: presc.rate,
            product_id: presc.product_id, showDrug: false, frequency: presc.frequency,
            prescription_detial_id: presc.prescription_detial_id, bill_id: presc.bill_id, isEdited: false,
            date: presc.date_created ? dateStringConvert(presc.date_created) : null, invoice_no: presc.invoice_no,
            category_name: presc.category_name, prescription_id: presc.prescription_id,
            uuid: uuidv4(), quantity:presc.quantity_prescribed,stock:presc.department_quantity,
            quantity_billable:presc.quantity_billed,quantity_to_bill:presc.insurance_id ?  presc.quantity : 0,
            pack_unit:presc.insurance_id ?  presc.quantity : null, is_provided:presc.insurance_id && presc.quantity
        }))

        const sundriesArr = prescrip.filter(item=>item.category_name==='Sundries')
        if(sundriesArr.length > 0){
            setIsSundries(true)
        }
       const sunArr = sundriesArr.filter(item=> item.prescription_id === prescription_id && (item.pay_status === 0 || item.status===3)).filter(drug => drug.product_id).map(item=>({ product_name: item.product_name, product_id: item.product_id, quantity: item.quantity, category_name: item.category_name, prescription_detial_id: item.prescription_detial_id,  isEdited: false, showDrug:false, isError:false, isRemoved:false}))
        const otherMeds = other_meds.filter(item=>item.prescription_id === prescription_id)

        const presArr = dt.filter(drug => drug.category_name === 'Medicine')



        setPrescription(presArr)
        setSundries(sunArr)

        setOthers(otherMeds);

        if (otherMeds.length > 0) {
            setOther(true)
        }
        patient_type === "1" ? setIsOutPatient(true): setIsOutPatient(false)


    }, [request, prescription_id]);




    // const handleClosePrintDialog = () => {
    //     setOpenPrintDialog(false)
    // };

    useEffect(()=> {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cdoctor/retrieve_inpatient_stopped_prescription`,formData).then(res =>{
            const data = res.data;
            const dt = data ? data : {};
            const stp_presc = dt.stopped_prescription ? dt.stopped_prescription : [];
            const stopped = stp_presc.map(presc => ({
                drug_name: presc.product_name, dosage: presc.dosage, status: presc.status,
                number_of_days: presc.number_of_days, key_information: presc.key_information, rate: presc.price,
                product_id: presc.product_id, showDrug: false, frequency: presc.frequency, uuid: uuidv4(),
                prescription_detial_id: presc.prescription_detial_id, bill_id: presc.bill_id, isEdited: false,
                date: presc.date_created ? dateStringConvert(presc.date_created) : null, invoice_no: presc.invoice_no,
                prescription_id: presc.prescription_id}))
            setStoppedPresc(stopped)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChangeItems = (event, index) => {
        const {name, value} = event.target;
        const arr = prescription.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value, isEdited: item.prescription_detial_id, invoice_no: item.invoice_no}
            }
            return item
        })
        const edited = arr.filter((item)=>item.isEdited)

        setEditedInvoices(edited)
        setPrescription(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const handleChangeOtherMedicine = (event, index) => {
        const {value, name} = event.target
        const arr = others.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setOthers(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    }

    const handleAddOther = () => {
        setOthers([...others, ...initialOthers])
    }

    const handleRemoveOther = (index) => {
        const arr = others.filter((_, idx) => idx !== index)
        setOthers(arr)
        setIsSubmitted('idle')
    }

    const handleAddRow = () => {
        const filtered_Prescription = prescription.filter(item=>!item.isExpired)
        setPrescription([...filtered_Prescription, ...initialPrescState])
        setAlert({...alert, open: false})
    };


    const removeRow = (item_uuid) => {
        const arr = prescription.filter((e) => e.uuid !== item_uuid);
        const removed = prescription.filter((e) => e.uuid === item_uuid);
        setPrescription(arr);
        setRemovedInvoices([...removedInvoices, ...removed])
        setIsSubmitted('idle')
        setAlert({...alert, open: false})
    };

    const filteredArr = (arr) =>{
        
        return arr.filter(item=>Boolean(item)).filter(item=>item.product_id && !item.isExpired).map(presc => ({
            dosage: presc.dosage,
            number_of_days: +presc.number_of_days,
            key_information: presc.key_information,
            rate: presc.rate,
            product_id: presc.product_id,
            frequency: presc.frequency,
            quantity_prescribed:+presc.quantity,
            is_provided: presc.is_provided,
            is_provider: presc.is_provider,
            currency_id:presc.currency_id,
            quantity_billable:presc.quantity_billable,
            prescription_detial_id: presc.prescription_detial_id,
            prescription_id: +presc.prescription_id,
            quantity_to_bill:+presc.quantity_to_bill,
            bill_details:{
                product_id: presc.product_id,
                rate: +presc.rate,
                quantity:+presc.quantity,
                invoice_no: presc.invoice_no,
                bill_id: +presc.bill_id,
                prescription_id: +presc.prescription_id,
                prescription_detial_id: presc.prescription_detial_id
            },
            bill_type:3,
           
        }))
    }

    // const filteredInArr = (arr) =>{
    //     return arr.filter(item=>Boolean(item)).filter(item=>item.product_id && !item.isExpired).map(presc => ({
    //         dosage: presc.dosage,
    //         number_of_days: +presc.number_of_days,
    //         key_information: presc.key_information,
    //         rate: presc.rate,
    //         product_id: presc.product_id,
    //         frequency: presc.frequency,
    //         quantity_prescribed:parseInt(presc.quantity),
    //         is_provided: presc.is_provided,
    //         is_provider: presc.is_provider,
    //         currency_id:presc.currency_id,
    //         quantity_billable:presc.quantity_billable,
    //         prescription_detial_id: +presc.prescription_detial_id,
    //         prescription_id: +presc.prescription_id,
    //         quantity_to_bill:+presc.quantity_to_bill,
    //         // bill_details:{
    //         //     product_id: +presc.product_id,
    //         //     rate: +presc.rate,
    //         //     quantity:+presc.quantity,
    //         //     invoice_no: presc.invoice_no,
    //         //     bill_id: +presc.bill_id,
    //         //     prescription_id: +presc.prescription_id
    //         // },
    //         bill_type:3,
    //
    //     }))
    // }



    

    const handleSubmit = (event) => {
        event.preventDefault();
        const {sundriesRemoved}= sundryProps;
        const arr = prescription.filter(item=>!item.isExpired).every(item => item.product_id || (other && !item.product_id))
        const isSundry = sundries.every(item => item.product_name) ;

        const sunArr = isSundries ?    arr && isSundry : arr
        const onlySunArr = isSundries&& !arr ? isSundry : null
        setSubmitted(true);
        setIsBlocking(false)


        const products = prescription.map(item=>({
            ...item,
            prescription_id
        }))
        if (sunArr || onlySunArr) {
            setIsSubmitted('pending');
            const inparams = {
                bill_type: 3,
                prescription_id,
                products: filteredArr(products).filter(drug => !drug.prescription_detial_id),
                invoices_edited : filteredArr(editedInvoices),
                invoices_removed: filteredArr(removedInvoices).filter(drug => drug.prescription_detial_id),
                sundries_added: filteredArr(sundries).filter(item=>!item.prescription_detial_id),
                sundries_removed: filteredArr(sundriesRemoved),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).map(item=>({
                    bill_type:3,
                    ...item
                }))
            }




            const params = {
                bill_type: 3,
                prescription_id,
                products: filteredArr(products).filter(drug => !drug.prescription_detial_id),
                invoices_edited : filteredArr(editedInvoices),
                invoices_removed: filteredArr(removedInvoices).filter(drug => drug.prescription_detial_id),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).filter(item=>item.isRemoved).map(item=>({
                    bill_type:3,
                    ...item
                }))
                
                
            }
            const allParams = patient_type === '1' ? params : inparams;

            // const url = patient_type === '1' ? 'update_prescription' : 'update_inpatient_presc'
            axios.post(`${config.smsUrl}/cdoctor/update_prescription`, {
                visit_id, user_roles_id,
                other_products: others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                    drug.frequency || drug.number_of_days), ...allParams 
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const _id = dt.data ? dt.data : '';
                setPrescId(_id);
                actions.snackbarActions.snackSuccess(`Prescription updated successfully`);
                setOpenPrintDialog(true);
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOpenEdit(false)
            })
            .catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);

            })
        }
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const handleClick = () => {
        history.push(`/prescriptiondetails/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${presc_id}`)
    };


    const editProps = {handleClick,openEdit, handleOpenEdit, handleCloseEdit,
        prescription,  handleChangeItems, handleAddRow,handleSubmit,
        removeRow,  other, others, handleChangeOtherMedicine,printDialog, id,
        handleAddOther, handleRemoveOther,  submitted, ...drugProps, isPending, isSundries, sundries, handleToggleSundries,sundryProps,
        isRejected, isResolved ,isOutPatient, isBlockDialog, isBlocking, setIsBlocking, setIsBlockDialog
    }

    return {editProps}
}